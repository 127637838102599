import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/runner/work/website/website/src/mdx/templates/default/MDXDefaultLayout.jsx";
import { CodePen } from "@uc-common/codepen";
import { Notification } from '~/src/components/Typography';
import { Link } from '~/src/components/Link';
import schema from '~/src/pages/guides/ld-json-schemas/responsive.json';
import { InlineCode } from '~/src/mdx/components/InlineCode/InlineCode';
export const frontmatter = {
  title: 'How to make images responsive with Uploadcare image transfrmations',
  description: 'Uploadcare provides an extensive Responsive Images toolkit. Build adaptive designs and optimize your web app performance. To use the feature, learn more information in Uploadcare docs.',
  header: 'How to make images responsive with Uploadcare image transfrmations',
  schema
};
export const _frontmatter = {};
const layoutProps = {
  frontmatter,
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">




    <p>{`Follow this guide to get quick and convenient ways to use image transformations in your project.`}</p>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#connect-a-script-to-deliver-optimized-images-to-users-automatically"
        }}>{`Use a ready-made script`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#set-automatic-image-format-and-quality-on-delivery"
        }}>{`Set automatic quality and format on delivery`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#make-a-single-image-responsive"
        }}>{`Make an image responsive`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#optimize-the-image-for-high-pixel-ratios"
        }}>{`Optimize for high pixel ratios`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#optimize-an-image-for-different-screen-widths-and-breakpoints"
        }}>{`Optimize for breakpoints`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#crop-to-object-and-enable-art-direction"
        }}>{`Crop to object and enable art direction`}</a></li>
    </ul>
    <p>
  <picture>
    <img src="https://ucarecdn.com/81c96e9f-f430-41c1-a147-e315a9cc54a1/-/format/jpeg/-/crop/1300x642/100,100/-/resize/1200x/-/quality/better/" srcSet="https://ucarecdn.com/81c96e9f-f430-41c1-a147-e315a9cc54a1/-/format/jpeg/-/crop/1300x642/100,100/-/resize/1200x/-/quality/lighter/ 2x" alt="Responsive image on mobile devices, laptop, and desktop" />
    </picture>
    </p>
    <p>{`Select one or several solutions that suit your needs. Either connect a ready-made library or take advantage of Uploadcare’s URL API and compression algorithms in order to make your pages lightweight and responsive.`}</p>
    <Notification mdxType="Notification">
We recommend using the `&lt;picture&gt;` element for all informative and functional images as it extends the `&lt;img&gt;` element and provides a way to add media queries, select alternative media formats, and allow art direction.
    </Notification>
    <h2 {...{
      "id": "connect-a-script-to-deliver-optimized-images-to-users-automatically"
    }}><a parentName="h2" {...{
        "href": "#connect-a-script-to-deliver-optimized-images-to-users-automatically"
      }}>{`Connect a script to deliver optimized images to users automatically`}</a></h2>
    <p>{`Probably the easiest and most universal way to optimize images of your website on the fly is by connecting the `}<a parentName="p" {...{
        "href": "https://uploadcare.com/products/adaptive-delivery/"
      }}>{`Adaptive Delivery`}</a>{` to your project. Image optimization is performed on the fly at the time of user requests and the results get cached on our CDN nodes. It also lets you either store images with Uploadcare or keep them on your hosting.`}</p>
    <p>{`See our `}<a parentName="p" {...{
        "href": "https://uploadcare.com/docs/delivery/adaptive-delivery/"
      }}>{`documentation`}</a>{` for detailed info. There are three steps:`}</p>
    <ol>
      <li parentName="ol">{`Connect the Adaptive Delivery JS script (remember to use your own `}<a parentName="li" {...{
          "href": "https://uploadcare.com/docs/start/settings/#keys-public"
        }}>{`public API key`}</a>{` instead of `}<code parentName="li" {...{
          "className": "lang-text"
        }}>{`YOUR_PUBLIC_KEY`}</code>{`).`}</li>
    </ol>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "js"
    }}><pre parentName="div" {...{
        "className": "lang-js"
      }}><code parentName="pre" {...{
          "className": "lang-js"
        }}><span parentName="code" {...{
            "className": "token operator"
          }}>{`<`}</span>{`script`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span>{`
    `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token keyword"
          }}>{`function`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token parameter"
          }}>{`src`}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`,`}</span>{` cb`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
    `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`var`}</span>{` s `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` document`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`createElement`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`'script'`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{` s`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`setAttribute`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`'src'`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` src`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
    s`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`onload `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` cb`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`document`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`head `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`||`}</span>{` document`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`body`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`appendChild`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`s`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
  `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`'https://ucarecdn.com/libs/blinkloader/3.x/blinkloader.min.js'`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`function`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
    window`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`Blinkloader`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`optimize`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
      `}<span parentName="code" {...{
            "className": "token literal-property property"
          }}>{`pubkey`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`'YOUR_PUBLIC_KEY'`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
      `}<span parentName="code" {...{
            "className": "token literal-property property"
          }}>{`fadeIn`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token boolean"
          }}>{`true`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
      `}<span parentName="code" {...{
            "className": "token literal-property property"
          }}>{`lazyload`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token boolean"
          }}>{`true`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
      `}<span parentName="code" {...{
            "className": "token literal-property property"
          }}>{`smartCompression`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token boolean"
          }}>{`true`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
      `}<span parentName="code" {...{
            "className": "token literal-property property"
          }}>{`responsive`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token boolean"
          }}>{`true`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
      `}<span parentName="code" {...{
            "className": "token literal-property property"
          }}>{`retina`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token boolean"
          }}>{`true`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
      `}<span parentName="code" {...{
            "className": "token literal-property property"
          }}>{`webp`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token boolean"
          }}>{`true`}</span>{`
    `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
  `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`
`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`<`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`/`}</span>{`script`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span></code></pre></div>
    <ol {...{
      "start": 2
    }}>
      <li parentName="ol">
        <p parentName="li">{`Whitelist image sources, if your images are hosted outside of Uploadcare CDN.
On your Uploadcare dashboard, go to ”Settings” — ”Proxy”, click “Connect”, and add domains you are planning to use.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Replace `}<code parentName="p" {...{
            "className": "lang-text"
          }}>{`src`}</code>{` attributes in your `}<code parentName="p" {...{
            "className": "lang-text"
          }}>{`<img>`}</code>{` tags with `}<code parentName="p" {...{
            "className": "lang-text"
          }}>{`data-blink-src`}</code>{` attributes:`}</p>
        <div parentName="li" {...{
          "className": "gatsby-highlight",
          "data-language": "html"
        }}><pre parentName="div" {...{
            "className": "lang-html"
          }}><code parentName="pre" {...{
              "className": "lang-html"
            }}><span parentName="code" {...{
                "className": "token tag"
              }}><span parentName="span" {...{
                  "className": "token tag"
                }}><span parentName="span" {...{
                    "className": "token punctuation"
                  }}>{`<`}</span>{`img`}</span>{` `}<span parentName="span" {...{
                  "className": "token attr-name"
                }}>{`data-blink-src`}</span><span parentName="span" {...{
                  "className": "token attr-value"
                }}><span parentName="span" {...{
                    "className": "token punctuation attr-equals"
                  }}>{`=`}</span><span parentName="span" {...{
                    "className": "token punctuation"
                  }}>{`"`}</span>{`https://somestorage.example.com/somepicture.png`}<span parentName="span" {...{
                    "className": "token punctuation"
                  }}>{`"`}</span></span>{` `}<span parentName="span" {...{
                  "className": "token attr-name"
                }}>{`alt`}</span><span parentName="span" {...{
                  "className": "token attr-value"
                }}><span parentName="span" {...{
                    "className": "token punctuation attr-equals"
                  }}>{`=`}</span><span parentName="span" {...{
                    "className": "token punctuation"
                  }}>{`"`}</span><span parentName="span" {...{
                    "className": "token punctuation"
                  }}>{`"`}</span></span><span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`>`}</span></span>{`

`}<span parentName="code" {...{
                "className": "token comment"
              }}>{`<!-- also works for background images -->`}</span>{`
`}<span parentName="code" {...{
                "className": "token tag"
              }}><span parentName="span" {...{
                  "className": "token tag"
                }}><span parentName="span" {...{
                    "className": "token punctuation"
                  }}>{`<`}</span>{`div`}</span>{` `}<span parentName="span" {...{
                  "className": "token attr-name"
                }}>{`data-blink-src`}</span><span parentName="span" {...{
                  "className": "token attr-value"
                }}><span parentName="span" {...{
                    "className": "token punctuation attr-equals"
                  }}>{`=`}</span><span parentName="span" {...{
                    "className": "token punctuation"
                  }}>{`"`}</span>{`https://somestorage.example.com/somebanner.png`}<span parentName="span" {...{
                    "className": "token punctuation"
                  }}>{`"`}</span></span><span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`>`}</span></span>{`
  This block contains a banner.
`}<span parentName="code" {...{
                "className": "token tag"
              }}><span parentName="span" {...{
                  "className": "token tag"
                }}><span parentName="span" {...{
                    "className": "token punctuation"
                  }}>{`</`}</span>{`div`}</span><span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`>`}</span></span>{`

`}<span parentName="code" {...{
                "className": "token comment"
              }}>{`<!-- you can use file UUID for images stored on Uploadcare CDN -->`}</span>{`
`}<span parentName="code" {...{
                "className": "token tag"
              }}><span parentName="span" {...{
                  "className": "token tag"
                }}><span parentName="span" {...{
                    "className": "token punctuation"
                  }}>{`<`}</span>{`img`}</span>{` `}<span parentName="span" {...{
                  "className": "token attr-name"
                }}>{`data-blink-uuid`}</span><span parentName="span" {...{
                  "className": "token attr-value"
                }}><span parentName="span" {...{
                    "className": "token punctuation attr-equals"
                  }}>{`=`}</span><span parentName="span" {...{
                    "className": "token punctuation"
                  }}>{`"`}</span>{`36f90908-afb9-4e0a-b254-1b769faf8f3a`}<span parentName="span" {...{
                    "className": "token punctuation"
                  }}>{`"`}</span></span>{`
`}<span parentName="span" {...{
                  "className": "token attr-name"
                }}>{`data-blink-quality`}</span><span parentName="span" {...{
                  "className": "token attr-value"
                }}><span parentName="span" {...{
                    "className": "token punctuation attr-equals"
                  }}>{`=`}</span><span parentName="span" {...{
                    "className": "token punctuation"
                  }}>{`"`}</span>{`smart-retina`}<span parentName="span" {...{
                    "className": "token punctuation"
                  }}>{`"`}</span></span>{` `}<span parentName="span" {...{
                  "className": "token attr-name"
                }}>{`alt`}</span><span parentName="span" {...{
                  "className": "token attr-value"
                }}><span parentName="span" {...{
                    "className": "token punctuation attr-equals"
                  }}>{`=`}</span><span parentName="span" {...{
                    "className": "token punctuation"
                  }}>{`"`}</span><span parentName="span" {...{
                    "className": "token punctuation"
                  }}>{`"`}</span></span><span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`>`}</span></span></code></pre></div>
      </li>
    </ol>
    <p>{`You can also add various filters and effects and select various delivery options that make images responsive, optimized, and lazy-loaded.`}</p>
    <h2 {...{
      "id": "set-automatic-image-format-and-quality-on-delivery"
    }}><a parentName="h2" {...{
        "href": "#set-automatic-image-format-and-quality-on-delivery"
      }}>{`Set automatic image format and quality on delivery`}</a></h2>
    <p>{`When you store your images on Uploadcare’s CDN, you get access to automatic smart conversion and compression that are available via URL operations. These features are handled by our own highly efficient compression algorithms.`}</p>
    <p>{`Take an original image:`}</p>
    <p>
  <picture>
    <img src="https://ucarecdn.com/36f90908-afb9-4e0a-b254-1b769faf8f3a/-/format/jpeg/-/resize/1200x/-/quality/better/" srcSet="https://ucarecdn.com/36f90908-afb9-4e0a-b254-1b769faf8f3a/-/format/jpeg/-/resize/1200x/-/quality/lighter/ 2x" alt="Greenpaws the Chameleon holding a branch" />
    </picture>
    </p>
    <p><a parentName="p" {...{
        "href": "https://ucarecdn.com/36f90908-afb9-4e0a-b254-1b769faf8f3a/"
      }}><code parentName="a" {...{
          "className": "lang-text"
        }}>{`https://ucarecdn.com/36f90908-afb9-4e0a-b254-1b769faf8f3a/`}</code></a></p>
    <p>{`Adding `}<code parentName="p" {...{
        "className": "lang-text"
      }}>{`-/format/auto/`}</code>{` to this URL will ensure that the image `}<a parentName="p" {...{
        "href": "https://uploadcare.com/docs/transformations/image/compression/#operation-format"
      }}>{`format`}</a>{` is selected automatically for each user, based on client's device, browser, and the presence of the alpha channel.`}</p>
    <p>{`You can also trust our CDN to select the image `}<a parentName="p" {...{
        "href": "https://uploadcare.com/docs/transformations/image/compression/#operation-quality"
      }}>{`quality`}</a>{` for user devices and browsers. `}<code parentName="p" {...{
        "className": "lang-text"
      }}>{`-/quality/smart/`}</code>{` and `}<code parentName="p" {...{
        "className": "lang-text"
      }}>{`-/quality/smart_retina/`}</code>{` will get maximum image compression without any noticeable visual artifacts. Note that `}<code parentName="p" {...{
        "className": "lang-text"
      }}>{`-/quality/smart/`}</code>{` is applied by default to every picture that has any processing operation applied to it. We recommend you set `}<code parentName="p" {...{
        "className": "lang-text"
      }}>{`-/quality/smart_retina/`}</code>{` for screens with high DPI:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "html"
    }}><pre parentName="div" {...{
        "className": "lang-html"
      }}><code parentName="pre" {...{
          "className": "lang-html"
        }}><span parentName="code" {...{
            "className": "token comment"
          }}>{`<!-- replace 36f90908-afb9-4e0a-b254-1b769faf8f3a with your image UUID -->`}</span>{`

`}<span parentName="code" {...{
            "className": "token comment"
          }}>{`<!-- -/quality/smart/ is implied -->`}</span>{`
`}<span parentName="code" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`<`}</span>{`img`}</span>{` `}<span parentName="span" {...{
              "className": "token attr-name"
            }}>{`src`}</span><span parentName="span" {...{
              "className": "token attr-value"
            }}><span parentName="span" {...{
                "className": "token punctuation attr-equals"
              }}>{`=`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`"`}</span>{`https://ucarecdn.com/36f90908-afb9-4e0a-b254-1b769faf8f3a/-/format/auto/`}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`"`}</span></span>{` `}<span parentName="span" {...{
              "className": "token attr-name"
            }}>{`alt`}</span><span parentName="span" {...{
              "className": "token attr-value"
            }}><span parentName="span" {...{
                "className": "token punctuation attr-equals"
              }}>{`=`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`"`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`"`}</span></span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`>`}</span></span>{`
`}<span parentName="code" {...{
            "className": "token comment"
          }}>{`<!-- for retina screens, add -/quality/smart_retina/ -->`}</span>{`
`}<span parentName="code" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`<`}</span>{`img`}</span>{` `}<span parentName="span" {...{
              "className": "token attr-name"
            }}>{`src`}</span><span parentName="span" {...{
              "className": "token attr-value"
            }}><span parentName="span" {...{
                "className": "token punctuation attr-equals"
              }}>{`=`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`"`}</span>{`https://ucarecdn.com/36f90908-afb9-4e0a-b254-1b769faf8f3a/-/quality/smart_retina/-/format/auto/`}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`"`}</span></span>{` `}<span parentName="span" {...{
              "className": "token attr-name"
            }}>{`alt`}</span><span parentName="span" {...{
              "className": "token attr-value"
            }}><span parentName="span" {...{
                "className": "token punctuation attr-equals"
              }}>{`=`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`"`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`"`}</span></span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`>`}</span></span></code></pre></div>
    <h2 {...{
      "id": "make-a-single-image-responsive"
    }}><a parentName="h2" {...{
        "href": "#make-a-single-image-responsive"
      }}>{`Make a single image responsive`}</a></h2>
    <p>{`Take a look at a more traditional approach that doesn’t require any JS.`}</p>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://uploadcare.com/docs/transformations/image/resize-crop/#operation-resize"
        }}>{`Resize`}</a>{` your picture to fit the widest container. We recommend using resizing using one dimension to preserve the aspect ratio.`}</li>
      <li parentName="ul">{`Set `}<code parentName="li" {...{
          "className": "lang-text"
        }}>{`width: 100%;`}</code>{` declaration to the image block, so that the image adapts to the container width automatically.`}</li>
    </ul>
    <p>{`Say, your `}<a parentName="p" {...{
        "href": "https://ucarecdn.com/36f90908-afb9-4e0a-b254-1b769faf8f3a/"
      }}>{`original image`}</a>{` resolution is 4928 x 3280 px, and the file size is 1.85 MB:`}</p>
    <p><a parentName="p" {...{
        "href": "https://ucarecdn.com/36f90908-afb9-4e0a-b254-1b769faf8f3a/"
      }}><code parentName="a" {...{
          "className": "lang-text"
        }}>{`https://ucarecdn.com/36f90908-afb9-4e0a-b254-1b769faf8f3a/`}</code></a></p>
    <p>{`Also, your container width is limited to 1200 px on the widest screens. Use the `}<code parentName="p" {...{
        "className": "lang-text"
      }}>{`resize`}</code>{` operator in the image URL in order to reduce the resolution of the image and its file size:`}</p>
    <p><a parentName="p" {...{
        "href": "https://ucarecdn.com/36f90908-afb9-4e0a-b254-1b769faf8f3a/-/resize/1200x/"
      }}><code parentName="a" {...{
          "className": "lang-text"
        }}>{`https://ucarecdn.com/36f90908-afb9-4e0a-b254-1b769faf8f3a/-/resize/1200x/`}</code></a></p>
    <p>{`Add the updated image URL to the `}<code parentName="p" {...{
        "className": "lang-text"
      }}>{`src`}</code>{` attribute of the `}<code parentName="p" {...{
        "className": "lang-text"
      }}>{`<img>`}</code>{` tag:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "html"
    }}><pre parentName="div" {...{
        "className": "lang-html"
      }}><code parentName="pre" {...{
          "className": "lang-html"
        }}><span parentName="code" {...{
            "className": "token comment"
          }}>{`<!-- replace 36f90908-afb9-4e0a-b254-1b769faf8f3a with your image UUID -->`}</span>{`

`}<span parentName="code" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`<`}</span>{`img`}</span>{` `}<span parentName="span" {...{
              "className": "token attr-name"
            }}>{`src`}</span><span parentName="span" {...{
              "className": "token attr-value"
            }}><span parentName="span" {...{
                "className": "token punctuation attr-equals"
              }}>{`=`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`"`}</span>{`https://ucarecdn.com/36f90908-afb9-4e0a-b254-1b769faf8f3a/-/resize/1200x/`}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`"`}</span></span>{` `}<span parentName="span" {...{
              "className": "token attr-name"
            }}>{`alt`}</span><span parentName="span" {...{
              "className": "token attr-value"
            }}><span parentName="span" {...{
                "className": "token punctuation attr-equals"
              }}>{`=`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`"`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`"`}</span></span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`>`}</span></span>{`
`}<span parentName="code" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`<`}</span>{`style`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`>`}</span></span><span parentName="code" {...{
            "className": "token style"
          }}><span parentName="span" {...{
              "className": "token language-css"
            }}>{`
 `}<span parentName="span" {...{
                "className": "token selector"
              }}>{`img`}</span>{` `}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`{`}</span>{`
   `}<span parentName="span" {...{
                "className": "token property"
              }}>{`width`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`:`}</span>{` 100%`}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`;`}</span>{`
   `}<span parentName="span" {...{
                "className": "token property"
              }}>{`height`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`:`}</span>{` auto`}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`;`}</span>{`
 `}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`}`}</span>{`
`}</span></span><span parentName="code" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`</`}</span>{`style`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`>`}</span></span></code></pre></div>
    <p>{`Play around with this `}<a parentName="p" {...{
        "href": "https://codepen.io/uploadcare/pen/vGaBzp"
      }}>{`live example`}</a>{` and make sure to check the device mode and the network tab in DevTools to see the way the image properties are changing.`}</p>
    <p>{`Here, the original image width has been reduced to 1000px, and the file size has been reduced to just 125 KB.`}</p>
    {
      /* https://codepen.io/uploadcare/pen/vGaBzp */
    }
    <CodePen defaultTab="result" id="vGaBzp" title="Responsive Images Example #1: <img> & css" mdxType="CodePen" />
    <Notification mdxType="Notification">
  Since we&apos;re going to spend some time with our green chameleon pal, let&apos;s give him a name — Greenpaws.
    </Notification>
    <h2 {...{
      "id": "optimize-the-image-for-high-pixel-ratios"
    }}><a parentName="h2" {...{
        "href": "#optimize-the-image-for-high-pixel-ratios"
      }}>{`Optimize the image for high pixel ratios`}</a></h2>
    <p>{`Set an additional version of the image for HiDPI screens with the `}<a parentName="p" {...{
        "href": "https://developer.mozilla.org/en-US/docs/Web/HTML/Element/img#Example_3_Using_the_srcset_attribute"
      }}><code parentName="a" {...{
          "className": "lang-text"
        }}>{`srcset attribute`}</code></a>{` of the `}<code parentName="p" {...{
        "className": "lang-text"
      }}>{`<img>`}</code>{` element and the `}<code parentName="p" {...{
        "className": "lang-text"
      }}>{`x`}</code>{` descriptor.`}</p>
    <p>{`Retina screens have a pixel ratio of 2. To optimize for them:`}</p>
    <ul>
      <li parentName="ul">{`multiply the `}<code parentName="li" {...{
          "className": "lang-text"
        }}>{`resize`}</code>{` value by 2`}</li>
      <li parentName="ul">{`reduce the image quality using the `}<a parentName="li" {...{
          "href": "https://uploadcare.com/docs/transformations/image/compression/#operation-quality"
        }}><code parentName="a" {...{
            "className": "lang-text"
          }}>{`quality`}</code>{` operation`}</a></li>
      <li parentName="ul">{`add the alternative image link to the `}<code parentName="li" {...{
          "className": "lang-text"
        }}>{`srcset`}</code>{` attribute followed by the `}<code parentName="li" {...{
          "className": "lang-text"
        }}>{`2x`}</code>{` descriptor that ties the image to devices with 2x pixel ratio`}</li>
    </ul>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "html"
    }}><pre parentName="div" {...{
        "className": "lang-html"
      }}><code parentName="pre" {...{
          "className": "lang-html"
        }}><span parentName="code" {...{
            "className": "token comment"
          }}>{`<!-- replace 36f90908-afb9-4e0a-b254-1b769faf8f3a with your image UUID -->`}</span>{`

`}<span parentName="code" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`<`}</span>{`img`}</span>{`
  `}<span parentName="span" {...{
              "className": "token attr-name"
            }}>{`src`}</span><span parentName="span" {...{
              "className": "token attr-value"
            }}><span parentName="span" {...{
                "className": "token punctuation attr-equals"
              }}>{`=`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`"`}</span>{`https://ucarecdn.com/36f90908-afb9-4e0a-b254-1b769faf8f3a/-/resize/1200x/1.jpg`}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`"`}</span></span>{`
  `}<span parentName="span" {...{
              "className": "token attr-name"
            }}>{`srcset`}</span><span parentName="span" {...{
              "className": "token attr-value"
            }}><span parentName="span" {...{
                "className": "token punctuation attr-equals"
              }}>{`=`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`"`}</span>{`https://ucarecdn.com/36f90908-afb9-4e0a-b254-1b769faf8f3a/-/resize/2400x/-/quality/lightest/2.jpg 2x`}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`"`}</span></span>{`
  `}<span parentName="span" {...{
              "className": "token attr-name"
            }}>{`alt`}</span><span parentName="span" {...{
              "className": "token attr-value"
            }}><span parentName="span" {...{
                "className": "token punctuation attr-equals"
              }}>{`=`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`"`}</span>{`Greenpaws the Chameleon`}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`"`}</span></span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`>`}</span></span></code></pre></div>
    <p>{`Here is a `}<a parentName="p" {...{
        "href": "https://codepen.io/uploadcare/pen/dMjbwV"
      }}>{`live example`}</a>{` that uses the same image of Greenpaws on Uploadcare CDN to produce an alternative retina version of it:`}</p>
    {
      /* https://codepen.io/uploadcare/pen/dMjbwV */
    }
    <CodePen defaultTab="result" id="dMjbwV" title="Responsive Images Example #2: <img> with srcset & css" mdxType="CodePen" />
    <p>{`However, this 2400px wide image is hardly necessary for an iPhone version of the website that needs only 320px width by 2x pixel ratio, which gives us 640px.`}</p>
    <p>{`If you want to adapt your images to various screen widths in the smartest way, continue to the next section.`}</p>
    <h2 {...{
      "id": "optimize-an-image-for-different-screen-widths-and-breakpoints"
    }}><a parentName="h2" {...{
        "href": "#optimize-an-image-for-different-screen-widths-and-breakpoints"
      }}>{`Optimize an image for different screen widths and breakpoints`}</a></h2>
    <p>{`You may use either an expanded new `}<code parentName="p" {...{
        "className": "lang-text"
      }}>{`<picture>`}</code>{` HTML element, or a traditional `}<code parentName="p" {...{
        "className": "lang-text"
      }}>{`<img>`}</code>{` element.`}</p>
    <h3 {...{
      "id": "using-img"
    }}><a parentName="h3" {...{
        "href": "#using-img"
      }}>{`Using `}<code parentName="a" {...{
          "className": "lang-text"
        }}>{`<img>`}</code></a></h3>
    <p>{`Use URL API to:`}</p>
    <ul>
      <li parentName="ul">{`create various resized versions of the image for different screen widths`}</li>
      <li parentName="ul">{`add them to the `}<code parentName="li" {...{
          "className": "lang-text"
        }}>{`srcset`}</code>{` attribute followed by a `}<code parentName="li" {...{
          "className": "lang-text"
        }}>{`w`}</code>{` descriptor with screen width in pixels`}</li>
      <li parentName="ul">{`add the `}<code parentName="li" {...{
          "className": "lang-text"
        }}>{`sizes`}</code>{` attribute to the `}<code parentName="li" {...{
          "className": "lang-text"
        }}>{`<img>`}</code>{` element to set primitive media conditions. It should contain information on how much of the viewport will be occupied by the image`}</li>
    </ul>
    <p>{`Here is an expanded example from the previous section that now contains various versions of an image optimized for many different widths in `}<code parentName="p" {...{
        "className": "lang-text"
      }}>{`w`}</code>{` descriptors:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "html"
    }}><pre parentName="div" {...{
        "className": "lang-html"
      }}><code parentName="pre" {...{
          "className": "lang-html"
        }}><span parentName="code" {...{
            "className": "token comment"
          }}>{`<!-- replace 36f90908-afb9-4e0a-b254-1b769faf8f3a with your image UUID -->`}</span>{`

`}<span parentName="code" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`<`}</span>{`img`}</span>{` `}<span parentName="span" {...{
              "className": "token attr-name"
            }}>{`src`}</span><span parentName="span" {...{
              "className": "token attr-value"
            }}><span parentName="span" {...{
                "className": "token punctuation attr-equals"
              }}>{`=`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`"`}</span>{`https://ucarecdn.com/36f90908-afb9-4e0a-b254-1b769faf8f3a/-/resize/1200x/fallback.jpg`}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`"`}</span></span>{`
  `}<span parentName="span" {...{
              "className": "token attr-name"
            }}>{`srcset`}</span><span parentName="span" {...{
              "className": "token attr-value"
            }}><span parentName="span" {...{
                "className": "token punctuation attr-equals"
              }}>{`=`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`"`}</span>{`https://ucarecdn.com/36f90908-afb9-4e0a-b254-1b769faf8f3a/-/resize/320x/320.jpg 320w,
    https://ucarecdn.com/36f90908-afb9-4e0a-b254-1b769faf8f3a/-/resize/450x/450.jpg 450w,
    https://ucarecdn.com/36f90908-afb9-4e0a-b254-1b769faf8f3a/-/resize/640x/640.jpg 640w,
    https://ucarecdn.com/36f90908-afb9-4e0a-b254-1b769faf8f3a/-/resize/750x/750.jpg 750w,
    https://ucarecdn.com/36f90908-afb9-4e0a-b254-1b769faf8f3a/-/resize/800x/800.jpg 800w,
    https://ucarecdn.com/36f90908-afb9-4e0a-b254-1b769faf8f3a/-/resize/900x/900.jpg 900w,
    https://ucarecdn.com/36f90908-afb9-4e0a-b254-1b769faf8f3a/-/resize/1000x/-/quality/lighter/1000.jpg 1000w,
    https://ucarecdn.com/36f90908-afb9-4e0a-b254-1b769faf8f3a/-/resize/1200x/-/quality/lighter/1200.jpg 1200w,
    https://ucarecdn.com/36f90908-afb9-4e0a-b254-1b769faf8f3a/-/resize/1500x/-/quality/lighter/1500.jpg 1500w,
    https://ucarecdn.com/36f90908-afb9-4e0a-b254-1b769faf8f3a/-/resize/1600x/-/quality/lighter/1600.jpg 1600w,
    https://ucarecdn.com/36f90908-afb9-4e0a-b254-1b769faf8f3a/-/resize/2000x/-/quality/lightest/2000.jpg 2000w`}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`"`}</span></span>{`
  `}<span parentName="span" {...{
              "className": "token attr-name"
            }}>{`sizes`}</span><span parentName="span" {...{
              "className": "token attr-value"
            }}><span parentName="span" {...{
                "className": "token punctuation attr-equals"
              }}>{`=`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`"`}</span>{`(min-width: 1200px) 50vw, 90vw`}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`"`}</span></span>{`
  `}<span parentName="span" {...{
              "className": "token attr-name"
            }}>{`alt`}</span><span parentName="span" {...{
              "className": "token attr-value"
            }}><span parentName="span" {...{
                "className": "token punctuation attr-equals"
              }}>{`=`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`"`}</span>{`Greenpaws the Chameleon`}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`"`}</span></span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`>`}</span></span></code></pre></div>
    <Notification mdxType="Notification">
  The `x` descriptor is incompatible with the `w` descriptor.
    </Notification>
    <p>{`In this example, this value of `}<code parentName="p" {...{
        "className": "lang-text"
      }}>{`sizes`}</code>{` tells the browser to set the image width to `}<code parentName="p" {...{
        "className": "lang-text"
      }}>{`50vw`}</code>{` if the viewport width is 1000px or more; otherwise, occupy most of the screen width:
`}<code parentName="p" {...{
        "className": "lang-text"
      }}>{`sizes="(min-width: 1200px) 50vw, 90vw"`}</code></p>
    <p>{`Take a look at this `}<a parentName="p" {...{
        "href": "https://codepen.io/uploadcare/pen/oxMvme"
      }}>{`live example`}</a>{` to see our pal Greenpaws adapt to various screen sizes, and make sure to try some of your own pictures in it.`}</p>
    {
      /* https://codepen.io/uploadcare/pen/oxMvme */
    }
    <CodePen defaultTab="result" id="oxMvme" title="Responsive Images Example #3: <img> with srcset & sizes" mdxType="CodePen" />
    <h3 {...{
      "id": "using-picture"
    }}><a parentName="h3" {...{
        "href": "#using-picture"
      }}>{`Using `}<code parentName="a" {...{
          "className": "lang-text"
        }}>{`<picture>`}</code></a></h3>
    <p>{`The semantic `}<a parentName="p" {...{
        "href": "https://developer.mozilla.org/en-US/docs/Web/HTML/Element/picture"
      }}><code parentName="a" {...{
          "className": "lang-text"
        }}>{`<picture>`}</code>{` element`}</a>{` expands `}<code parentName="p" {...{
        "className": "lang-text"
      }}>{`<img>`}</code>{` and offers more ways of manipulating the images because it allows you to use actual media queries.`}</p>
    <p>{`Use `}<a parentName="p" {...{
        "href": "https://developer.mozilla.org/en-US/docs/Web/HTML/Element/source"
      }}><code parentName="a" {...{
          "className": "lang-text"
        }}>{`<source>`}</code>{` elements`}</a>{` within the parent `}<code parentName="p" {...{
        "className": "lang-text"
      }}>{`<picture>`}</code>{` element. Include a `}<code parentName="p" {...{
        "className": "lang-text"
      }}>{`srcset`}</code>{` attribute that can have `}<code parentName="p" {...{
        "className": "lang-text"
      }}>{`sizes`}</code>{` and `}<code parentName="p" {...{
        "className": "lang-text"
      }}>{`media`}</code>{` attributes.`}</p>
    <p>{`Use URL API to:`}</p>
    <ul>
      <li parentName="ul">{`create various resized versions of the image for different screen widths`}</li>
      <li parentName="ul">{`add them to the `}<code parentName="li" {...{
          "className": "lang-text"
        }}>{`srcset`}</code>{` attributes of `}<code parentName="li" {...{
          "className": "lang-text"
        }}>{`<source>`}</code>{` elements`}</li>
      <li parentName="ul">{`add a `}<code parentName="li" {...{
          "className": "lang-text"
        }}>{`media`}</code>{` attribute to specify media conditions`}</li>
      <li parentName="ul">{`add a `}<code parentName="li" {...{
          "className": "lang-text"
        }}>{`sizes`}</code>{` attribute to specify the portion of the screen occupied by the image`}</li>
      <li parentName="ul">{`add at least one version of an image to the child `}<code parentName="li" {...{
          "className": "lang-text"
        }}>{`<img>`}</code>{` element that will act as a fallback.`}</li>
    </ul>
    <p>{`Here is an example of how the `}<code parentName="p" {...{
        "className": "lang-text"
      }}>{`<picture>`}</code>{` element may be used:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "html"
    }}><pre parentName="div" {...{
        "className": "lang-html"
      }}><code parentName="pre" {...{
          "className": "lang-html"
        }}>{`
`}<span parentName="code" {...{
            "className": "token comment"
          }}>{`<!-- replace 36f90908-afb9-4e0a-b254-1b769faf8f3a with your image UUID -->`}</span>{`

`}<span parentName="code" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`<`}</span>{`picture`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`>`}</span></span>{`
  `}<span parentName="code" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`<`}</span>{`source`}</span>{`
    `}<span parentName="span" {...{
              "className": "token attr-name"
            }}>{`srcset`}</span><span parentName="span" {...{
              "className": "token attr-value"
            }}><span parentName="span" {...{
                "className": "token punctuation attr-equals"
              }}>{`=`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`"`}</span>{`https://ucarecdn.com/36f90908-afb9-4e0a-b254-1b769faf8f3a/-/resize/450x/450.jpg`}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`"`}</span></span>{`
    `}<span parentName="span" {...{
              "className": "token attr-name"
            }}>{`sizes`}</span><span parentName="span" {...{
              "className": "token attr-value"
            }}><span parentName="span" {...{
                "className": "token punctuation attr-equals"
              }}>{`=`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`"`}</span>{`90vw`}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`"`}</span></span>{`
    `}<span parentName="span" {...{
              "className": "token attr-name"
            }}>{`media`}</span><span parentName="span" {...{
              "className": "token attr-value"
            }}><span parentName="span" {...{
                "className": "token punctuation attr-equals"
              }}>{`=`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`"`}</span>{`(max-width: 450px) and (orientation: portrait)`}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`"`}</span></span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`>`}</span></span>{`
  `}<span parentName="code" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`<`}</span>{`source`}</span>{`
    `}<span parentName="span" {...{
              "className": "token attr-name"
            }}>{`srcset`}</span><span parentName="span" {...{
              "className": "token attr-value"
            }}><span parentName="span" {...{
                "className": "token punctuation attr-equals"
              }}>{`=`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`"`}</span>{`https://ucarecdn.com/36f90908-afb9-4e0a-b254-1b769faf8f3a/-/resize/320x/320.jpg 320w,
      https://ucarecdn.com/36f90908-afb9-4e0a-b254-1b769faf8f3a/-/resize/450x/450.jpg 450w,
      https://ucarecdn.com/36f90908-afb9-4e0a-b254-1b769faf8f3a/-/resize/640x/640.jpg 640w,
      https://ucarecdn.com/36f90908-afb9-4e0a-b254-1b769faf8f3a/-/resize/750x/750.jpg 750w,
      https://ucarecdn.com/36f90908-afb9-4e0a-b254-1b769faf8f3a/-/resize/800x/800.jpg 800w,
      https://ucarecdn.com/36f90908-afb9-4e0a-b254-1b769faf8f3a/-/resize/900x/900.jpg 900w,
      https://ucarecdn.com/36f90908-afb9-4e0a-b254-1b769faf8f3a/-/resize/1000x/-/quality/lighter/1000.jpg 1000w,
      https://ucarecdn.com/36f90908-afb9-4e0a-b254-1b769faf8f3a/-/resize/1200x/-/quality/lighter/1200.jpg 1200w,
      https://ucarecdn.com/36f90908-afb9-4e0a-b254-1b769faf8f3a/-/resize/1500x/-/quality/lighter/1500.jpg 1500w,
      https://ucarecdn.com/36f90908-afb9-4e0a-b254-1b769faf8f3a/-/resize/1600x/-/quality/lighter/1600.jpg 16000w,
      https://ucarecdn.com/36f90908-afb9-4e0a-b254-1b769faf8f3a/-/resize/2000x/-/quality/lightest/2000.jpg 2000w`}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`"`}</span></span>{`
    `}<span parentName="span" {...{
              "className": "token attr-name"
            }}>{`sizes`}</span><span parentName="span" {...{
              "className": "token attr-value"
            }}><span parentName="span" {...{
                "className": "token punctuation attr-equals"
              }}>{`=`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`"`}</span>{`50vw`}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`"`}</span></span>{`
    `}<span parentName="span" {...{
              "className": "token attr-name"
            }}>{`media`}</span><span parentName="span" {...{
              "className": "token attr-value"
            }}><span parentName="span" {...{
                "className": "token punctuation attr-equals"
              }}>{`=`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`"`}</span>{`(min-width: 1200px)`}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`"`}</span></span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`>`}</span></span>{`
  `}<span parentName="code" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`<`}</span>{`img`}</span>{` `}<span parentName="span" {...{
              "className": "token attr-name"
            }}>{`src`}</span><span parentName="span" {...{
              "className": "token attr-value"
            }}><span parentName="span" {...{
                "className": "token punctuation attr-equals"
              }}>{`=`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`"`}</span>{`https://ucarecdn.com/36f90908-afb9-4e0a-b254-1b769faf8f3a/-/resize/1000x/fallback.jpg`}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`"`}</span></span>{` `}<span parentName="span" {...{
              "className": "token attr-name"
            }}>{`alt`}</span><span parentName="span" {...{
              "className": "token attr-value"
            }}><span parentName="span" {...{
                "className": "token punctuation attr-equals"
              }}>{`=`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`"`}</span>{`Greenpaws the Chameleon`}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`"`}</span></span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`>`}</span></span>{`
`}<span parentName="code" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`</`}</span>{`picture`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`>`}</span></span></code></pre></div>
    <p>{`The `}<code parentName="p" {...{
        "className": "lang-text"
      }}>{`media`}</code>{` attribute allows you to specify `}<a parentName="p" {...{
        "href": "https://developer.mozilla.org/en-US/docs/Web/CSS/Media_Queries/Using_media_queries"
      }}>{`media queries`}</a>{`, and it will allow the browser to select the most suitable source for every kind of device and viewport you have specified.`}</p>
    <p>{`Now Greenpaws can get different display versions based on screen orientation:`}</p>
    <p><a parentName="p" {...{
        "href": "https://codepen.io/uploadcare/pen/eYemNOq"
      }}>{`Example`}</a></p>
    {
      /* https://codepen.io/uploadcare/pen/eYemNOq */
    }
    <CodePen defaultTab="result" id="eYemNOq" title="Responsive Images Example: <picture> with srcset, media and sizes" mdxType="CodePen" />
    <h3 {...{
      "id": "using-css"
    }}><a parentName="h3" {...{
        "href": "#using-css"
      }}>{`Using CSS`}</a></h3>
    <p>{`You can also set different versions of an image with breakpoints using CSS `}<a parentName="p" {...{
        "href": "https://developer.mozilla.org/en-US/docs/Web/CSS/Media_Queries/Using_media_queries"
      }}>{`media queries`}</a>{`.`}</p>
    <p>{`That might be useful if an image is not part of the HTML content — like a banner or a background image. Use the `}<a parentName="p" {...{
        "href": "https://developer.mozilla.org/en-US/docs/Web/CSS/image-set()"
      }}><code parentName="a" {...{
          "className": "lang-text"
        }}>{`image-set()`}</code>{` CSS function`}</a>{` to set new image versions, say, for normal and HiDPI screens:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "css"
    }}><pre parentName="div" {...{
        "className": "lang-css"
      }}><code parentName="pre" {...{
          "className": "lang-css"
        }}><span parentName="code" {...{
            "className": "token comment"
          }}>{`/* replace 36f90908-afb9-4e0a-b254-1b769faf8f3a with your image UUID */`}</span>{`

`}<span parentName="code" {...{
            "className": "token property"
          }}>{`background-image`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token function"
          }}>{`image-set`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`
  `}<span parentName="code" {...{
            "className": "token url"
          }}><span parentName="span" {...{
              "className": "token function"
            }}>{`url`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`(`}</span><span parentName="span" {...{
              "className": "token string url"
            }}>{`"https://ucarecdn.com/36f90908-afb9-4e0a-b254-1b769faf8f3a/-/resize/1200x/1.jpg"`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`)`}</span></span>{` 1x`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
  `}<span parentName="code" {...{
            "className": "token url"
          }}><span parentName="span" {...{
              "className": "token function"
            }}>{`url`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`(`}</span><span parentName="span" {...{
              "className": "token string url"
            }}>{`"https://ucarecdn.com/36f90908-afb9-4e0a-b254-1b769faf8f3a/-/resize/2400x/-/quality/lightest/2.jpg"`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`)`}</span></span>{` 2x
`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span></code></pre></div>
    <p>{`Try out this live `}<a parentName="p" {...{
        "href": "https://codepen.io/uploadcare/pen/LNBYLd"
      }}>{`example`}</a>{`:`}</p>
    {
      /* https://codepen.io/uploadcare/pen/LNBYLd */
    }
    <CodePen defaultTab="result" id="LNBYLd" title="Responsive Images Example #7: CSS background image" mdxType="CodePen" />
    <p>{`Or use this ready-made `}<a parentName="p" {...{
        "href": "https://codepen.io/uploadcare/pen/NNBWvW"
      }}>{`SCSS function`}</a>{` for the same purpose:`}</p>
    {
      /* https://codepen.io/uploadcare/pen/NNBWvW */
    }
    <CodePen defaultTab="result" id="NNBWvW" title="Responsive Images Example #8: CSS background image, Sass version" mdxType="CodePen" />
    <h2 {...{
      "id": "crop-to-object-and-enable-art-direction"
    }}><a parentName="h2" {...{
        "href": "#crop-to-object-and-enable-art-direction"
      }}>{`Crop to object and enable art direction`}</a></h2>
    <h3 {...{
      "id": "scale-instead-of-resizing"
    }}><a parentName="h3" {...{
        "href": "#scale-instead-of-resizing"
      }}>{`Scale instead of resizing`}</a></h3>
    <p>{`Normally, your image ends up looking the same in portrait versions of devices, on desktops, and in landscape versions. That may be fine for square images, but the subjects of the wide images will probably end up looking too small on mobiles:`}</p>
    {
      /* TODO: replace with component */
    }
    <p>
  <img src="https://ucarecdn.com/13e0f19e-579b-485b-acf9-2b5e5e5fc464/-/crop/1300x546/100,153/-/resize/650x/" srcSet="https://ucarecdn.com/13e0f19e-579b-485b-acf9-2b5e5e5fc464/-/crop/1300x546/100,153/-/quality/lightest/ 2x" alt="Landscape-oriented image resized on a smartphone" />
    </p>
    <p>{`Automatically cropping the image to its object will help you resolve this issue:`}</p>
    {
      /* TODO: replace with component */
    }
    <p>
  <img src="https://ucarecdn.com/fea6ab4e-b90d-4780-996b-dd13ff7971f7/-/crop/400x451/100,246/-/resize/200x/" srcSet="https://ucarecdn.com/fea6ab4e-b90d-4780-996b-dd13ff7971f7/-/crop/400x451/100,246/-/quality/lightest/ 2x" alt="Portrait-oriented resize works netter for the smartphone screen" />
    </p>
    <p>{`To do that, take advantage of `}<a parentName="p" {...{
        "href": "https://uploadcare.com/docs/transformations/image/resize-crop/#operation-crop"
      }}><code parentName="a" {...{
          "className": "lang-text"
        }}>{`scale_crop`}</code></a>{` operations instead of resizing the images and combine them with media queries.`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "html"
    }}><pre parentName="div" {...{
        "className": "lang-html"
      }}><code parentName="pre" {...{
          "className": "lang-html"
        }}><span parentName="code" {...{
            "className": "token comment"
          }}>{`<!-- replace 36f90908-afb9-4e0a-b254-1b769faf8f3a with your image UUID -->`}</span>{`

`}<span parentName="code" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`<`}</span>{`picture`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`>`}</span></span>{`
  `}<span parentName="code" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`<`}</span>{`source`}</span>{`
    `}<span parentName="span" {...{
              "className": "token attr-name"
            }}>{`srcset`}</span><span parentName="span" {...{
              "className": "token attr-value"
            }}><span parentName="span" {...{
                "className": "token punctuation attr-equals"
              }}>{`=`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`"`}</span>{`https://ucarecdn.com/36f90908-afb9-4e0a-b254-1b769faf8f3a/-/scale_crop/320x569/center/320-569.jpg 320w,
      https://ucarecdn.com/36f90908-afb9-4e0a-b254-1b769faf8f3a/-/scale_crop/640x1138/center/-/quality/lightest/640-1138.jpg 640w,
      https://ucarecdn.com/36f90908-afb9-4e0a-b254-1b769faf8f3a/-/scale_crop/450x800/center/450-800.jpg 450w,
      https://ucarecdn.com/36f90908-afb9-4e0a-b254-1b769faf8f3a/-/scale_crop/900x1600/center/-/quality/lightest/900-1600.jpg 900w`}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`"`}</span></span>{`
    `}<span parentName="span" {...{
              "className": "token attr-name"
            }}>{`sizes`}</span><span parentName="span" {...{
              "className": "token attr-value"
            }}><span parentName="span" {...{
                "className": "token punctuation attr-equals"
              }}>{`=`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`"`}</span>{`90vw`}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`"`}</span></span>{`
    `}<span parentName="span" {...{
              "className": "token attr-name"
            }}>{`media`}</span><span parentName="span" {...{
              "className": "token attr-value"
            }}><span parentName="span" {...{
                "className": "token punctuation attr-equals"
              }}>{`=`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`"`}</span>{`(max-width: 450px) and (orientation: portrait)`}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`"`}</span></span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`>`}</span></span>{`

  `}<span parentName="code" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`<`}</span>{`img`}</span>{` `}<span parentName="span" {...{
              "className": "token attr-name"
            }}>{`src`}</span><span parentName="span" {...{
              "className": "token attr-value"
            }}><span parentName="span" {...{
                "className": "token punctuation attr-equals"
              }}>{`=`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`"`}</span>{`https://ucarecdn.com/36f90908-afb9-4e0a-b254-1b769faf8f3a/-/resize/1200x/fallback.jpg`}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`"`}</span></span>{` `}<span parentName="span" {...{
              "className": "token attr-name"
            }}>{`alt`}</span><span parentName="span" {...{
              "className": "token attr-value"
            }}><span parentName="span" {...{
                "className": "token punctuation attr-equals"
              }}>{`=`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`"`}</span>{`Greenpaws the Chameleon`}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`"`}</span></span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`>`}</span></span>{`
`}<span parentName="code" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`</`}</span>{`picture`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`>`}</span></span></code></pre></div>
    <p>{`Here is a `}<a parentName="p" {...{
        "href": "https://codepen.io/uploadcare/pen/EKpxgX/"
      }}>{`live example`}</a>{` that features our pal Greenpaws. To see this solution work, change your device mode to portrait in the DevTools and reduce the screen width:`}</p>
    {
      /* https://codepen.io/uploadcare/pen/EKpxgX */
    }
    <CodePen defaultTab="result" id="EKpxgX" title="Responsive Images Example #4: <picture> and media" mdxType="CodePen" />
    <h3 {...{
      "id": "art-direction"
    }}><a parentName="h3" {...{
        "href": "#art-direction"
      }}>{`Art direction`}</a></h3>
    <p>{`To make `}<a parentName="p" {...{
        "href": "https://developer.mozilla.org/en-US/docs/Learn/HTML/Multimedia_and_embedding/Responsive_images#art_direction"
      }}>{`art direction`}</a>{` possible, you’re going to need to create specific image views for different breakpoints. Use `}<code parentName="p" {...{
        "className": "lang-text"
      }}>{`crop`}</code>{` together with the `}<code parentName="p" {...{
        "className": "lang-text"
      }}>{`resize`}</code>{` operator to achieve this goal.`}</p>
    <p>{`Say, you want to display a horizontal image with just the Greenpaws’ head on smartphones:`}</p>
    {
      /* TODO: replace with component */
    }
    <img src="https://ucarecdn.com/fedadedc-5d9f-4484-b88a-3f7ebeee8a4b/-/crop/1300x547/100,143/-/resize/650x/" srcSet="https://ucarecdn.com/fedadedc-5d9f-4484-b88a-3f7ebeee8a4b/-/crop/1300x547/100,143/-/quality/lightest/ 2x" alt="Horizontal image resized on a smartphone screen" />
    <p>{`Take the previous example and just change the `}<code parentName="p" {...{
        "className": "lang-text"
      }}>{`scale_crop`}</code>{` operator to the
`}<code parentName="p" {...{
        "className": "lang-text"
      }}>{`crop`}</code>{` operator with the `}<code parentName="p" {...{
        "className": "lang-text"
      }}>{`resize`}</code>{` operator.`}</p>
    <p>{`Modify your image links, so that the `}<code parentName="p" {...{
        "className": "lang-text"
      }}>{`crop`}</code>{` operation is followed by `}<code parentName="p" {...{
        "className": "lang-text"
      }}>{`resize`}</code>{`:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "html"
    }}><pre parentName="div" {...{
        "className": "lang-html"
      }}><code parentName="pre" {...{
          "className": "lang-html"
        }}><span parentName="code" {...{
            "className": "token comment"
          }}>{`<!-- replace 36f90908-afb9-4e0a-b254-1b769faf8f3a with your image UUID -->`}</span>{`

`}<span parentName="code" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`<`}</span>{`picture`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`>`}</span></span>{`
  `}<span parentName="code" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`<`}</span>{`source`}</span>{`
    `}<span parentName="span" {...{
              "className": "token attr-name"
            }}>{`srcset`}</span><span parentName="span" {...{
              "className": "token attr-value"
            }}><span parentName="span" {...{
                "className": "token punctuation attr-equals"
              }}>{`=`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`"`}</span>{`https://ucarecdn.com/36f90908-afb9-4e0a-b254-1b769faf8f3a/-/crop/2048x1365/1345,670/-/resize/320x/320.jpg 320w,
      https://ucarecdn.com/36f90908-afb9-4e0a-b254-1b769faf8f3a/-/crop/2048x1365/1345,670/-/resize/640x/640.jpg 640w,
      https://ucarecdn.com/36f90908-afb9-4e0a-b254-1b769faf8f3a/-/crop/2048x1365/1345,670/-/resize/450x/450.jpg 450w,
      https://ucarecdn.com/36f90908-afb9-4e0a-b254-1b769faf8f3a/-/crop/2048x1365/1345,670/-/resize/900x/900.jpg 900w`}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`"`}</span></span>{`
    `}<span parentName="span" {...{
              "className": "token attr-name"
            }}>{`sizes`}</span><span parentName="span" {...{
              "className": "token attr-value"
            }}><span parentName="span" {...{
                "className": "token punctuation attr-equals"
              }}>{`=`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`"`}</span>{`90vw`}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`"`}</span></span>{`
    `}<span parentName="span" {...{
              "className": "token attr-name"
            }}>{`media`}</span><span parentName="span" {...{
              "className": "token attr-value"
            }}><span parentName="span" {...{
                "className": "token punctuation attr-equals"
              }}>{`=`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`"`}</span>{`(max-width: 450px) and (orientation: portrait)`}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`"`}</span></span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`>`}</span></span>{`

  `}<span parentName="code" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`<`}</span>{`img`}</span>{` `}<span parentName="span" {...{
              "className": "token attr-name"
            }}>{`src`}</span><span parentName="span" {...{
              "className": "token attr-value"
            }}><span parentName="span" {...{
                "className": "token punctuation attr-equals"
              }}>{`=`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`"`}</span>{`https://ucarecdn.com/36f90908-afb9-4e0a-b254-1b769faf8f3a/-/resize/1000x/fallback.jpg`}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`"`}</span></span>{` `}<span parentName="span" {...{
              "className": "token attr-name"
            }}>{`alt`}</span><span parentName="span" {...{
              "className": "token attr-value"
            }}><span parentName="span" {...{
                "className": "token punctuation attr-equals"
              }}>{`=`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`"`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`"`}</span></span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`>`}</span></span>{`
`}<span parentName="code" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`</`}</span>{`picture`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`>`}</span></span></code></pre></div>
    <p>{`Try resizing the viewport in this `}<a parentName="p" {...{
        "href": "https://codepen.io/uploadcare/pen/ZWjEeB"
      }}>{`live example`}</a>{`:`}</p>
    {
      /* https://codepen.io/uploadcare/pen/ZWjEeB */
    }
    <CodePen defaultTab="result" id="ZWjEeB" title="Responsive Images Example #5: <picture> and art direction" mdxType="CodePen" />
    <Notification mdxType="Notification">
  If your image contains a face, it&apos;s easy to use the <Link to='/docs/transformations/image/resize-crop/#operation-crop-tags' mdxType="Link">crop by object</Link> operation without additional resizing.
    </Notification>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      